import { defineStore } from "pinia";

export const useSportsStore = defineStore("sports", {
	state: () => ({
		sport_settings: null,
	}),
	getters: {
		getSportSettings: (state) => {
			return (slug) => {
				if (state.sport_settings) {
					return state.sport_settings.find(sport => sport.slug === slug);
				}
				return null;
			}
		  }
	},
	actions: {
		setSportSettings(data) {
			this.sport_settings = data;
		}
	},
});
