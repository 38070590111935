<template>
  
            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{ events_data?.p1_points }}</h5>
                    <span>{{ $t('basketball.stats.point') }}</span>
                    <h5>{{ events_data?.p2_points }}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_points+events_data.p2_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_points, events_data.p2_points)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data && events_data.p1_first_quarter_points !== undefined && events_data.p2_first_quarter_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_first_quarter_points}}</h5>
                    <span>{{ $t('basketball.stats.first_quarter_points') }}</span>
                    <h5>{{events_data?.p2_first_quarter_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_first_quarter_points+events_data.p2_first_quarter_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_first_quarter_points, events_data.p2_first_quarter_points)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data && events_data.p1_second_quarter_points !== undefined && events_data.p2_second_quarter_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_second_quarter_points}}</h5>
                    <span>{{ $t('basketball.stats.second_quarter_points') }}</span>
                    <h5>{{events_data?.p2_second_quarter_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_second_quarter_points+events_data.p2_second_quarter_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_second_quarter_points, events_data.p2_second_quarter_points)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data && events_data.p1_third_quarter_points !== undefined && events_data.p2_third_quarter_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_third_quarter_points}}</h5>
                    <span>{{ $t('basketball.stats.third_quarter_points') }}</span>
                    <h5>{{events_data?.p2_third_quarter_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_third_quarter_points+events_data.p2_third_quarter_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_third_quarter_points, events_data.p2_third_quarter_points)" max="100"></progress>
                </div>
            </div>
            <div class="statistics-range-main" v-if="events_data && events_data.p1_fourth_quarter_points !== undefined && events_data.p2_fourth_quarter_points !== undefined">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_fourth_quarter_points}}</h5>
                    <span>{{ $t('basketball.stats.fourth_quarter_points') }}</span>
                    <h5>{{events_data?.p2_fourth_quarter_points}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_fourth_quarter_points+events_data.p2_fourth_quarter_points>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_fourth_quarter_points, events_data.p2_fourth_quarter_points)" max="100"></progress>
                </div>
            </div>

            <!-- <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data.p1_red_cards}}</h5>
                    <span>{{ $t('soccer.stats.red_cards') }}</span>
                    <h5>{{events_data.p2_red_cards}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_red_cards+events_data.p2_red_cards>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_red_cards, events_data.p2_red_cards)" max="100"></progress>
                </div>
            </div> -->
</template>

<script>
import svgs from '../../assets/svgs.json'
import { storeToRefs } from 'pinia'
import { useFixtureStore } from '../../stores/Fixture'
export default {
    name: "StatsView",
    data() {
        return {
            svg_images: svgs,
            events_data: storeToRefs(useFixtureStore()).getSelectedFixture,
        }
    },
    methods: {
        getProgress(p1_score, p2_score){
            const total = parseInt(p1_score) + parseInt(p2_score);
            if(!total) return 0;
            const percentage = (p1_score / total) * 100;
            return percentage
        }
    },
}
</script>

<style>

</style>