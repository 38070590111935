<template>
  
            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{ events_data?.p1_score }}</h5>
                    <span>{{ $t('soccer.stats.score') }}</span>
                    <h5>{{ events_data?.p2_score }}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_score+events_data.p2_score>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_score, events_data.p2_score)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data?.p1_corners}}</h5>
                    <span>{{ $t('soccer.stats.corners') }}</span>
                    <h5>{{events_data?.p2_corners}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_corners+events_data.p2_corners>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_corners, events_data.p2_corners)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data.p1_yellow_cards}}</h5>
                    <span>{{ $t('soccer.stats.yellow_cards') }}</span>
                    <h5>{{events_data.p2_yellow_cards}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_yellow_cards+events_data.p2_yellow_cards>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_yellow_cards, events_data.p2_yellow_cards)" max="100"></progress>
                </div>
            </div>

            <div class="statistics-range-main" v-if="events_data">
                <div class="statistics-range-info">
                    <h5>{{events_data.p1_red_cards}}</h5>
                    <span>{{ $t('soccer.stats.red_cards') }}</span>
                    <h5>{{events_data.p2_red_cards}}</h5>
                </div>
                <div class="statistics-range">
                    <progress :class=" events_data.p1_red_cards+events_data.p2_red_cards>0 ? 'stats_data_both':''" :value="getProgress(events_data.p1_red_cards, events_data.p2_red_cards)" max="100"></progress>
                </div>
            </div>
</template>

<script>
import svgs from '../../assets/svgs.json'
import { storeToRefs } from 'pinia'
import { useFixtureStore } from '../../stores/Fixture'
export default {
    name: "StatsView",
    data() {
        return {
            svg_images: svgs,
            events_data: storeToRefs(useFixtureStore()).getSelectedFixture,
        }
    },
    methods: {
        getProgress(p1_score, p2_score){
            const total = parseInt(p1_score) + parseInt(p2_score);
            if(!total) return 0;
            const percentage = (p1_score / total) * 100;
            return percentage
        }
    },
}
</script>

<style>

</style>