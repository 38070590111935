/* eslint-disable no-unused-vars */
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { useSocketStore } from "./stores/Socket";
import router from "./router";
import { CommonData } from "./stores/Common";
import { createI18n } from "vue-i18n";
import locales from "./locales/locales";

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);
const socket_store = useSocketStore();
socket_store.initializeSocket();
const socketData = () => {
  /**
   * Server timestamp clock tick
   */
  socket_store.getSocket.on("server_timestamp", (data) => {
    CommonData().setServerTimestamp(data);
    setInterval(() => {
      CommonData().setServerTimestamp(data++);
    }, 1000);
  });
};
socketData();
var locale;
router.beforeEach((to, from, next) => {
  locale = to.params.locale || "en"; // Default to 'en' if no locale in route
  if (locale) {
    if (locale == "fa") {
      document.body.classList.add("site-rtl");
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
    const i18n = createI18n({
      locale: locale,
      messages: locales,
      legacy: false,
      globalInjection: true,
    });

    app.use(i18n);
  }
  next();
});

app.mount("#app");
