// socketStore.js
import { defineStore } from "pinia";
import io from "socket.io-client";
export const useSocketStore = defineStore("socket", {
  state: () => ({
    socket: null,
    locale: "en",
  }),
  getters: {
    getSocket() {
      return this.socket;
    },
  },
  actions: {
    initializeSocket() {
      this.socket = io(process.env.VUE_APP_SOCKET_URL + "ml", {
        path: process.env.VUE_APP_SOCKET_PATH,
        transports: ["websocket"],
      });
    },
  },
});
