import { defineStore } from "pinia";


export const CommonData = defineStore({
    id: "CommonData",
    state: () => ({
        server_timestamp: null,
        selectedLanguage: 'en'
    }),
    getters: {
        getSelectedLanguage(){
            return this.selectedLanguage;
        },
        isDebugMode() {
            if (process.env.VUE_APP_MODE == 'DEBUG') return true;
            return false;
        }
    },
    actions: {
        setSelectedLanguage(data) {
            this.selectedLanguage = data;
        },
        setServerTimestamp(data) {
            this.server_timestamp = data;
        },
        fixtureClockTime(data, seconds = false) {

            let seconds_diff = 0;
            let calculated_seconds = 0;

            // find diff between server_timestamp and data.clock_timestamp
            if (data.clock_status == 'running') {

                seconds_diff = Number(this.server_timestamp) - Number(data.clock_timestamp);
                if (data.clock_direction == 'up') {
                    calculated_seconds = Number(data.clock_seconds) + seconds_diff;
                } else if (data.clock_direction == 'down') {
                    calculated_seconds = Number(data.clock_seconds) - seconds_diff;
                }

            } else if (data.clock_status == 'stopped') {
                calculated_seconds = Number(data.clock_seconds);
            }

            if (calculated_seconds < 0) calculated_seconds = 0;

            if (calculated_seconds > 8400) calculated_seconds = 8434;

            // Calculate clock_time 'mm:ss'
            let mins = ~~(calculated_seconds / 3600) * 60 + ~~((calculated_seconds % 3600) / 60);
            if (!seconds) return mins;

            let secs = ~~calculated_seconds % 60;
            let time = '';
            time += (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '');
            time += '' + secs;

            return time;
        },

    },
    persist: true,
});
