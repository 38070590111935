<template>
<!-- statistics tabing start -->

<div class="playing-team-statistics">
    <div class="team-statistics-top">
        <div class="team-one">
            <h6>{{events_data?.p1_name}}</h6>
        </div>
        <div class="team-two">
            <h6>{{events_data?.p2_name}}</h6>
        </div>
    </div>
    <div class="statistics-panel-main" v-if="events_data">
        <div class="player-statistics-inner" v-for="(incident, index) in events_data.incidents" :key="index" :class="{'common_data_summary': incident.type === 'score_after_full_time' || incident.type === 'score_after_first_half' || incident.type === 'common'}">
            <div class="match-stats" :class="{ 'match-stats-left': incident['participant'] === 1, 'match-stats-right': incident['participant'] === 2}" v-if="incident.type !== 'score_after_full_time' || incident.type !== 'score_after_first_half' || incident.type !== 'common'">
                <div class="match-stats-icon" v-if="images_array.includes(incident.type)">
                    <object v-html="get_image(incident)"></object>
                </div>
                <div class="match-stats-info">
                    <h6>{{removeUnderScores(incident.type)}}</h6>
                    <p>{{incident.body}}</p>
                </div>
            </div>
            <div class="match-stats-time">
                <span v-if="incident.type !== 'score_after_full_time' || incident.type !== 'score_after_first_half' || incident.type !== 'common'">{{ incident.minute }}'</span>
                <span v-else>{{ incident.body }}</span>
            </div>
        </div>
    </div>

</div>
<!-- statistics tabing end -->
</template>

<script>
import svgs from '../../assets/svgs.json'
import { storeToRefs } from 'pinia'
import { useFixtureStore } from '../../stores/Fixture'
export default {
    name: "SummaryView",
    data() {
        return {
            svg_images: svgs,
            events_data: storeToRefs(useFixtureStore()).getSelectedFixture,
            images_array:['goal', 'corner', 'yellow_card', 'red_card', 'substitution'],
        }
    },
    methods: {
        get_image(incident) {
            const event_type = incident.type;
            if (event_type == 'goal') {
                return this.svg_images.ball;
            }
            if (event_type == 'corner') {
                return this.svg_images.corner;
            }
            if (event_type == 'yellow_card') {
                return this.svg_images.yellow_card;
            }
            if (event_type == 'red_card') {
                return this.svg_images.red_card;
            }
            if (event_type == 'substitution') {
                return this.svg_images.substitution;
            }
        },
        removeUnderScores(str) {
      // Remove underscores from incident type
      return str ? str.replace(/_/g, ' ') : " ";
    }
    },
}
</script>

<style>

</style>
