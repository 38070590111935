<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;800;900&display=swap');

html,
body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
}
* {
    box-sizing: border-box;
}
#app {
  font-family: "sofia-pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
/* colors */
:root {
    --white:#fff;
    --primary-color: #63a246;
    --secondary-color: #f7d107;
    --dark-color:#000;
    --bg-grey-dark:#363D47;
    --dark-grey:#202833;
    --grey-plain:#4E545D;
    --grey-solid:#878A90;
    --dark-blue:#202833;
    --list-font-size:14px;
    --progress-red:#D93444;
    
  }
/*End*/
button{
  font-family: "sofia-pro", sans-serif;
}
.live-badge{
     color: var(--dark-color);
    background: var(--secondary-color);
    border-radius: 3px;
    font-size: calc(var(--list-font-size) - 4px);
    width: 38px;
    height: 15px;
    text-align: center;
    margin-top: 2px;
}
.live-badge svg{ 
  width:10px
}
</style>
