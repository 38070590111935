import axios from "axios";
import { defineStore } from "pinia";
import { applyPatch } from "rfc6902";

export const useFixtureStore = defineStore("fixture", {
  state: () => ({
    available_fixtures: null,
    selected_fixture: null,
    last_animation_time: null,
    last_animation_name: null,
  }),
  getters: {
    getSelectedFixture() {
      return this.selected_fixture;
    },
  },
  actions: {
    async getAvailableFixtures() {
      let res = await axios.get(
        process.env.VUE_APP_API_BASE_URL + "available-fixtures"
      );
      return res.data;
    },
    setSelectedFixtureSnapshot(data) {
      this.selected_fixture = data;
    },
    updateSelectedFixture(data) {
      applyPatch(this.selected_fixture, data.patch);
    },
  },
});
